<template>
  <div class="container">
    <img class="pic-404" src="@/assets/404_images/404.png" alt="404">
    <div class="text-title">很抱歉，页面它不小心迷路了</div>
    <div class="text-desc">请检查您输入的网址是否正确，或点击以下按钮返回首页</div>
    <!-- <el-button class="bnt" type="primary" round><router-link to="/dashboard">返回首页</router-link></el-button>       -->
    <span class="bnt"><router-link to="/dashboard">返回首页</router-link></span>
  </div>
</template>

<script>

export default {
  name: 'Page404',
}
</script>

<style rel="stylesheet/less" lang="less" scoped>
.container{
  text-align: center;
}
.pic-404{
  width: 550px;
  height: 331px;
  position: relative;
  top: 213px;
}
.text-title{
  font-size:20px;
  font-family:PingFangSC-Semibold;
  font-weight:600;
  color:rgba(51,51,51,1);
  position: relative;
  top: 263px;
}
.text-desc{
  font-size:14px;
  font-family:PingFangSC-Regular;
  font-weight:400;
  color:rgba(153,153,153,1);
  position: relative;
  top: 278px;
}
.bnt{
  position: relative;
  top: 308px;
  background:rgba(66,133,244,1);
  border-radius:21px;
  padding: 10px 20px;
  color: #FFFFFF;
}

</style>
